var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter-container"},[_c('el-form',{attrs:{"inline":""}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入搜索关键词","clearable":""},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"danger","icon":"el-icon-refresh"},on:{"click":_vm.refresh}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.add}},[_vm._v("邀请服务中心")])],1)],1)],1),_c('el-table',{attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"uid","label":"UID","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"agentName","label":"服务中心名称","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"legalName","label":"签约公司名称","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"rewardTotal","label":"实际奖励总额","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"cellphone","label":"手机号","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.status[row.status])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"签约时间","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"expireTime","label":"过期时间","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-row',[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.edit(row.id)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.demotion(row.id)}}},[_vm._v("降级")])],1)]}}])})],1),_c('div',{staticClass:"page-container"},[_c('el-pagination',{attrs:{"background":"","total":_vm.count,"page-sizes":_vm.pageSizes,"page-size":_vm.pageSize,"current-page":_vm.page,"layout":"total, sizes, prev, pager, next, jumper"},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"size-change":_vm.refresh,"current-change":function (val) {
        _vm.page = val
        _vm.getList()
      }}})],1),_c('Form',{ref:"form",attrs:{"height":_vm.heightList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }